<template>
	<v-container id="printMe">
	  <v-row  justify-center  >

	  	<v-col cols="12" xl="12" md="12" xs="12" >
		  	<v-card>
					<v-card-title primary-title>
					  <v-flex xl4 lg4 md4 sm4 xs12>
					  	<v-img :src="logo" alt="alt"  width="200"/>
					  </v-flex>

					  <h1 class="text-xs-left red--text"><strong>{{ tipo}} {{Docum.Numdoc}}</strong></h1>
					  <v-spacer></v-spacer>
					  <!-- <v-btn  class="mx-3"color="amber darken-3" dark >Editar</v-btn> -->

						<v-btn color="blue-grey darken-3" value="Print this page" @click="mostrarComprobante">	<v-icon color="white" >print</v-icon>
						</v-btn>
						<v-spacer></v-spacer>

						<!-- <v-btn color="blue-grey darken-3" @click="print">	<v-icon color="white" >print</v-icon>
						</v-btn> -->

					</v-card-title>

		  		<v-card-text>
			  		<v-row justify-center row fill-height >

			  	    <v-flex xl6 lg6 md6 sm12 xs12>
			  	    	<v-card outlined>
					  	    <v-card-text>
					  	    	<div class="text-xs-left "><strong>{{ getCIA.nomcia }}</strong></div>
					  	    	<div class="text-xs-left font-weight-black">RFC {{ getCIA.rfccia }}</div>
					  	    	<div>{{ getCIA.calle + ' ' + getCIA.numext + ' ' + getCIA.colonia }} </div>
					  	    	<div>{{getCIA.ciudad + ' ' + getCIA.estado  }} </div>
					  	    	<div>CP {{getCIA.cp}}</div>

					  	    </v-card-text>
					  	  </v-card>
			  	    </v-flex>

					  	<v-flex xl6 lg6 md6 sm12 xs12>
			  	    	<v-card outlined>
			  	    	 <v-card-text>
  	    	  	<div class="text-xs-left font-weight-black">{{ Clientes.Nomcli }}</div>
  	    	  	<div class="text-xs-left red--text font-weight-black "> RFC {{ Clientes.Rfc }}</div>

		  		    <div class="text-xs-left ">{{ Clientes.Calle + ' ' + Clientes.Numext + ' ' + Clientes.Colonia }} </div>

		  		    <div>{{Clientes.Ciudad + ' ' + Clientes.Estado  }} </div>
					  	<div>CP {{Clientes.Cp}}</div>

  	    	  </v-card-text>
			  	    	</v-card>
			  	    </v-flex>

							<v-flex xl8 lg8 md6 sm6 xs112>
								<v-card class="transparent elevation-0">
								  <v-card-text>
								  	<div class="text-xs-left ">Email: {{ Clientes.Email1 }}</div>
		  		    			<div class="text-xs-left ">Telefono: {{ Clientes.telefono }}</div>

							      <!-- <v-radio-group v-model="radios" :mandatory="false" >
								      <v-radio label="Recoger en tienda" value="radio-1" color="primary"></v-radio>
								      <v-radio label="Envío a domicilio" value="radio-2" color="primary"></v-radio>
							      </v-radio-group> -->
							      <v-radio-group dense v-model="radios" :mandatory="false" row readonly>
											<v-radio v-if="radios==1" label="Recoger en tienda" value="1" color="primary"></v-radio>
											<v-radio v-if="radios==2"  label="Envío a domicilio" value="2" color="primary"></v-radio>
										</v-radio-group>

										{{Direnvio}}

								  </v-card-text>

								  

								</v-card>
					    </v-flex>

			  	    <v-flex xl4 lg4 md6 sm6 xs12>
					    	<v-card outlined >
					    		<v-row row wrap>
					    		  <v-flex xs6>
					    		  	<v-card class="transparent elevation-0" width="100%">
					    		  	  <v-card-text>
							    		    <div align="right"><h3><strong>Importe:</strong></h3></div>
							    		    <div align="right"><h3><strong>Descuento:</strong></h3></div>
							    		    <div v-if="mostrarPreciosNetos" align="right"><h3><strong>Subtotal:</strong></h3></div>
							    		    <div v-if="mostrarPreciosNetos" align="right"><h3><strong>Iva:</strong></h3></div>
							    		    <div align="right"><h3><strong>Total:</strong></h3></div>
					    		  	  </v-card-text>
					    		  	</v-card>
					    		  </v-flex>
					    		  <v-flex xs6>
					    		    <v-card class="transparent elevation-0" width="100%">
					    		  	  <v-card-text align="right">
							    		    <div><h3><strong>{{ Importe }}</strong></h3></div>
							    		    <div><h3><strong>{{ Descuento }}</strong></h3></div>
							    		    <div v-if="mostrarPreciosNetos"><h3><strong>{{ Subtotal }}</strong></h3></div>
							    		    <div v-if="mostrarPreciosNetos"><h3><strong>{{ Iva }}</strong></h3></div>
							    		    <div><h3><strong>{{ Total }}</strong></h3></div>
					    		  	  </v-card-text>
					    		  	</v-card>
					    		  </v-flex>
					    		</v-row>
					  		</v-card>
					    </v-flex>

						 	<v-flex xs12 class="pa-2">
						 		<v-divider></v-divider>
							  <template>
						    	<v-data-table
						      	:headers="headers"
						      	:items="MovinArray"
						      	:items-per-page="MovinArray.length"
						      	fixed-header
                		class="elevation-1 pa-0 text-xs-center"
						      	hide-default-footer
						    	>
							      <template v-slot:no-data>
							        <v-btn color="primary" @click="initialize">Reset</v-btn>
							      </template>
							    </v-data-table>
								</template>
						 	</v-flex>
			  		</v-row>
		  		</v-card-text>
		  	</v-card>
	  	</v-col>
	  </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueHtmlToPaper from 'vue-html-to-paper'
var accounting = require('accounting')
var base64 = require('base-64')

export default {
  data () {
    return {
    	Direnvio:'',
    	mostrarPreciosNetos: false,
      output: null,
      tipo: '',
      logo: '',
      Importe: 0,
      Descuento: 0,
      Subtotal: 0,
      Iva: 0,
      Total: 0,
      MovinArray: [{}],
      tipodoc: '',
      loading: true,
      Clientes: {},
      Movim: [{}],
      Docum: '',
      radios: 'radio-1',
      dialog: false,
      total: '',
      direccion: '',
	      headers: [
	        { text: 'Id', align: 'left', value: 'Numpar', sortable: false },
	        { text: 'Clave', align: 'left', value: 'Numart', sortable: false },
	        { text: 'Descripción', align: 'left', value: 'Descrip', sortable: false },
	        { text: 'Cantidad', align: 'center', value: 'Cant', sortable: false },
	        { text: 'Unidad', align: 'center', value: 'Unidad', sortable: false },
	        { text: 'Precio', align: 'right', value: 'Precio', sortable: false },
	        { text: 'Descuento', align: 'right', value: 'Pjedesc', sortable: false },
	        // { text: 'Impuesto'	   ,align: 'right',  value: 'Impuesto1'   ,sortable: false },
	        // { text: 'Importe', align: 'right', value: 'Importe', sortable: false },
	        // { text: 'Subtotal', align: 'right', value: 'Subtotal', sortable: false }
	        { text: 'Precio Neto'  ,align: 'right',  value: 'Total'       ,sortable: false }
	      ]
    }
  },

  created () {
  	this.getConfig


	  if (this.getConfig.preciosnetos == '1') {
      this.mostrarPreciosNetos = false
    }
    if (this.getConfig.preciosnetos == '0') {
      this.mostrarPreciosNetos = true
    }
    this.logo = this.getLogourl

    const tipodoc = this.$route.params.tipodoc
    // si no manda parametros rechaazar
    var iddocum = base64.decode(tipodoc)

    if (tipodoc) {
      	this.init(iddocum)
    }
  },

  computed: {
    ...mapGetters('carrito', ['getCarrito', 'getTC']),
    ...mapGetters('Login', ['getdatosUsuario', 'getClientes', 'getCliente', 'getCIA']),
    ...mapGetters('config', ['getConfig']),
    ...mapGetters('tema', ['getLogourl']),
    ...mapGetters('direccion', ['getDirecciones', 'getDireccion']),
  },

  methods: {
  	...mapActions('direccion', ['traerDirecciones']),

    	print () {
	      // Pass the element id here
	      this.$htmlToPaper('printMe')
	    },

    init (iddocum) {
      console.log("iddocum",iddocum)
      	this.loading = true

      	this.$http.get('api/v1/order.info/' + iddocum).then(response => {
      		console.log(response)
      		response.body.Importe = accounting.unformat(response.body.Importe)
      		response.body.Impuesto1 = accounting.unformat(response.body.Impuesto1)

      		this.total = accounting.formatNumber((parseFloat(response.body.Importe) + parseFloat(response.body.Impuesto1)), 2)
      		response.body.Importe = accounting.formatNumber(response.body.Importe, 2)
      		response.body.Impuesto1 = accounting.formatNumber(response.body.Impuesto1, 2)

      		this.Docum = response.body
      		this.Clientes = response.body.Clientes[0]
      		this.Movim = response.body.Movim

      		this.crearArreglo()
      		// Mostrar tipo de documento.
	      	if (this.Docum.Tipodoc == ' P') {
	    			this.tipo = 'Pedido'
	    		} else {
	    			this.tipo = 'Cotización'
	    		}

	    		console.log("Direnvio", this.Docum.Direnvio)

	    		if (this.Docum.Direnvio.trim() != '' && this.Docum.Numclisuc != '') {
    				this.Direnvio=this.Docum.Direnvio

 		   			console.log("getnumclisuc", this.Docum)
    				this.radios = '2'
    				this.Docum.numclisuc
	    		} else {
	    			this.radios = '1'
	    		}

	    		if (this.getConfig.preciosnetos == '1') {
			      this.mostrarPreciosNetos = false
			    }
			    if (this.getConfig.preciosnetos == '0') {
			      this.mostrarPreciosNetos = true
			    }
    

      }).catch(function (error) {
        console.log(error)
      }).finally(() => this.loading = false)
    },

    mostrarComprobante () {
     	  const tipodoc = this.$route.params.tipodoc
     	  var doc = { doc: tipodoc }
      this.$router.push({ name: 'impresion', params: { info: doc, origen: 'consultacoti', tipo: 'Cotización' } })
    },

    // PREPARAR MOVIM
    crearArreglo () {
      	/// /////////////////////////////((((((((((((((((()))))))))))))))))
      		this.Movim.forEach(element => {
        element.Precio = accounting.unformat(element.Precio)
        element.Impuesto1 = accounting.unformat(element.Impuesto1)
      		element.Impuesto2 = accounting.unformat(element.Impuesto2)
      		element.Importe = accounting.formatNumber(element.Cant * accounting.unformat(element.Precio), 2)
      		element.Iva = 0.00
      		element.Total = 0.00
      })

      this.MovinArray = this.Movim
      this.longitud = this.MovinArray.length

      	/// ///////////////////////////////
	    	for (var i = this.MovinArray.length - 1; i >= 0; i--) {
	    		if (this.MovinArray[i].Impuesto1 !== 0) {
	    			// si tienes descuento
	    			if (this.MovinArray[i].Pjedesc !== 0) {
	    				// se aplica el descuento
	    				var desc = 1 - (parseFloat(this.MovinArray[i].Pjedesc) / 100)
	    				// se aplica el Impuesto1
	    				var ivaiva = (this.MovinArray[i].Impuesto1 / 100)
	    				var ivaarticulo = 1 + (this.MovinArray[i].Impuesto1 / 100)
	    				// y ahora si se aplica primero al articulo el descuento y luego el Impuesto1
	    				this.MovinArray[i].Total = accounting.formatNumber(((this.MovinArray[i].Precio * this.MovinArray[i].Cant) * desc * ivaarticulo), 2)

	    				this.Descuento = this.Descuento + ((this.MovinArray[i].Precio * this.MovinArray[i].Cant) * (parseFloat(this.MovinArray[i].Pjedesc) / 100))

	    				this.MovinArray[i].Iva = accounting.formatNumber(((this.MovinArray[i].Precio * this.MovinArray[i].Cant) * ivaiva), 2)

	    				this.MovinArray[i].Subtotal = accounting.formatNumber(((this.MovinArray[i].Precio * this.MovinArray[i].Cant) * desc), 2)

	    				this.Iva = this.Iva + accounting.unformat(this.MovinArray[i].Subtotal) * ivaiva
	    			} else {
	    				// en caso de que no tenga descuento
	    				var ivaiva = (this.MovinArray[i].Impuesto1 / 100)
	    				var ivaarticulo = 1 + (this.MovinArray[i].Impuesto1 / 100)
	    				this.iva = this.iva + (this.MovinArray[i].Precio * this.MovinArray[i].Cant) * ivaiva
	    				this.MovinArray[i].Total = accounting.formatNumber(((this.MovinArray[i].Precio * this.MovinArray[i].Cant) * ivaarticulo), 2)
	    				this.MovinArray[i].Iva = accounting.formatNumber(((this.MovinArray[i].Precio * this.MovinArray[i].Cant) * ivaiva), 2)
	    				this.MovinArray[i].Subtotal = accounting.formatNumber((this.MovinArray[i].Precio * this.MovinArray[i].Cant), 2)
	    				this.Iva = this.Iva + accounting.unformat(this.MovinArray[i].Subtotal) * ivaiva
	    			}
	    		} else {
	    			// en caso de que no tenga Impuesto1
	    			if (this.MovinArray[i].Pjedesc !== 0) {
	    				// se aplica el descuento
	    				var desc = 1 - (parseFloat(this.MovinArray[i].Pjedesc) / 100)
	    				this.MovinArray[i].Total = accounting.formatNumber(((this.MovinArray[i].Precio * this.MovinArray[i].Cant) * desc), 2)
	    				this.MovinArray[i].Subtotal = accounting.formatNumber(((this.MovinArray[i].Precio * this.MovinArray[i].Cant) * desc), 2)
	    				this.Descuento = this.Descuento + ((parseFloat(this.MovinArray[i].Pjedesc) / 100) * (this.MovinArray[i].Precio * this.MovinArray[i].Cant))
	    			} else {
	    				this.MovinArray[i].Total = accounting.formatNumber((this.MovinArray[i].Precio * this.MovinArray[i].Cant), 2)
	    			}
	    			// Formato
	    		}

	    		this.MovinArray[i].Precio = accounting.formatNumber(this.MovinArray[i].Precio, 2)
	    	}

	    	for (var i = this.MovinArray.length - 1; i >= 0; i--) {
	    		this.Importe = this.Importe + accounting.unformat(this.MovinArray[i].Importe)
	    		this.Subtotal = this.Subtotal + accounting.unformat(this.MovinArray[i].Subtotal)
	    		this.Total = this.Total + accounting.unformat(this.MovinArray[i].Total)
	    	}

	    	// ordenar
	    	// arr.sort((a,b)=>a-b)
	    	// this.MovinArray.sort((a,b)=>a-b)
	    	// console.log(this.MovinArray)

	    	this.Importe = accounting.formatNumber(this.Importe, 2)
	    	this.Subtotal = accounting.formatNumber(this.Subtotal, 2)
	    	this.Iva = accounting.formatNumber(this.Iva, 2)
	    	this.Total = accounting.formatNumber(this.Total, 2)
	    	this.Descuento = accounting.formatNumber(this.Descuento, 2)
    	}
  }
}
</script>
